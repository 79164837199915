import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Navigation from "../components/navigation"
import styled from "styled-components"

const JobPosition = styled.h2`
  font-size: 12px;
  margin-top: -10px;
`
const versions = [
  {
    version: "v25.5.9",
    framework: "HRLC",
    changelog: [
      "Getting used to HRLC, so far great life improvement",
      "Failed both note taking and budget tracking",
      "Installed Bass System, going on for 2 months",
      "Potential new and exciting new updates soon",
    ],
  },
  {
    version: "v25.0.0",
    framework: "HRLC",
    changelog: [
      "Updated to new HRLC Framework",
      "Started work on new note taking framework",
      "25 year milestone reached",
      "6 year in production reached",
      "Started work on testing for new improvements",
      "Implemented budgeting and spend tracking",
    ],
  },
  {
    version: "v24.12.0",
    framework: "N/A",
    changelog: [
      "Text and design updates",
      "Using handlebars mustache",
      "Clean cut head implemented",
    ],
  },
]
const ChangeLog = ({ data, location }) => {
  const { siteTitle, social, author } = data.site.siteMetadata
  return (
    <Layout location={location} title={siteTitle} social={social}>
      <Seo title="About" />
      <Navigation />
      <h1> Changelog: Marko Korevec </h1>
      <JobPosition>
        {author.job.position} @{" "}
        <a
          rel="noopener noreferrer"
          target="_BLANK"
          href={author.job.company.url}
        >
          {author.job.company.title}
        </a>
      </JobPosition>
      <p>
        Idea behind this page is to track progress and updates on the most
        important product in my life and that is myself. Updates are structured
        with <i>semver</i>. <br /> <br />
        Major version = years <br />
        minor = months <br />
        patch = days
      </p>
      <hr />
      {versions.map((version) => {
        return (
          <>
            <h2>{version.version}</h2>
            <p>
              <b>Framework</b>: {version.framework}
            </p>
            <ul>
              {version.changelog.map((change) => (
                <li key={change}>{change}</li>
              ))}
            </ul>
          </>
        )
      })}
    </Layout>
  )
}

export default ChangeLog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        author {
          job {
            show
            position
            company {
              title
              url
            }
          }
        }
        title
        social {
          title
          url
        }
      }
    }
  }
`
